import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Component imports
import { Navigation } from "./components/navigation";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import Slider from "./components/Slider";
import ContactPage from "./components/ContactPage";
import Product1 from "./components/Product1";
import Product2 from "./components/Product2";
import Product3 from "./components/Product3";
import Product4 from "./components/Product4";
import Product5 from "./components/Product5";
import Product6 from "./components/Product6";
import Product7 from "./components/Product7";
import Product8 from "./components/Product8";
import Policy from "./components/Policy";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import './index.css';
import HexawaveUv from "./Policey/HexawaveUv";
import PressData from "./Policey/PressData";
import BoylesData from "./Policey/BoylesData";
import Spotwave from "./Policey/Spotwave";
import InotPolicy from "./Policey/InotPolicy";
import FlowData from "./Policey/FlowData";
import OxyData from "./Policey/OxyData";
import ProctoCam from "./Policey/ProctoCam";
import Spinctopress from "./Policey/Sphinctopress";

// Smooth scrolling setup
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
 

  // Load data from JSON when the component mounts
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      {/* Always render the navigation bar */}
      <Navigation />
      
      <Routes>
        {/* Home page route */}
        <Route 
          path="/" 
          element={
            <>
              <Slider />
              <Features data={landingPageData.Features} />
              <Services data={landingPageData.Services} />
              <Gallery data={landingPageData.Gallery} />
              <Testimonials data={landingPageData.Testimonials} />
              <About data={landingPageData.About} />
              <Contact data={landingPageData.Contact} />
            </>
          } 
        />

     

        {/* Product pages routes */}
        <Route path="/InOT_Surgeons'_Control_Panel" element={<Product1 />} />
        <Route path="/HexaWave®_LED_Operation_Theatre_Light" element={<Product2 />} />
        <Route path="/SpotWave®_LED_Exam_Light" element={<Product3 />} />
        <Route path="HexaWave®_UV_Mobile_Sterilizer_for_OT_&_ICU" element={<Product4 />} />
        <Route path="/product5" element={<Product5 />} />
        <Route path="/Pressdata®_Medical_Gas_Alarm_+_Analyser" element={<Product6 />} />
        <Route path="/Flowdata_Medical_Gas_Flow_Analyser" element={<Product7 />} />
        <Route path="/Boylesdata_Digital_Monitor_For_Anaesthesia" element={<Product8 />} />
       
        {/* Contact page route */}
        <Route path="/contact" element={<Contact />} />

           {/* Static Policy page route */}
           <Route path="/HexaWave/Privacy-Policy" element={<Policy />} />
           <Route path="/HexaWaveUv/Privacy-Policy" element={<HexawaveUv/>}/>
           <Route path="/PressData/Privacy-Policy" element={<PressData/>}/>
           <Route path="/BoylesData/Privacy-Policy" element={<BoylesData/>}/>
           <Route path="/SpotWave/Privacy-Policy" element={<Spotwave/>}/>
          <Route path="/Inot/Privacy-Policy" element={<InotPolicy/>}/>
          <Route path="/FlowData/Privacy-Policy" element={<FlowData/>}/>
          <Route path="/OxyData/Privacy-Policy" element={<OxyData/>}/>
          <Route path="/ProctoCam/Privacy-Policy" element={<ProctoCam/>}/>
          <Route path="/SphinctoPress/Privacy-Policy" element={<Spinctopress/>}/>

      </Routes>
    </Router>
  );
};

export default App;

  
//   return (
//     <div>
//       <Navigation />
//       <Slider/>
//       {/* <Header datas={landingPageData.Header} /> */}

//        <Features data={landingPageData.Features} />

//       <Services data={landingPageData.Services} />
//       {/* <Gallery data={landingPageData.Gallery} /> */}
//       <Testimonials data={landingPageData.Testimonials} />
      
//       <About data={landingPageData.About} /> 
//       <Contact data={landingPageData.Contact} />
//       {/* <ContactPage/> */}
    
//     </div>
//   );
// };

// export default App;
