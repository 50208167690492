import React from "react";
import { useEffect } from "react";
import "./Product6.css";
import { Link } from 'react-router-dom';


const Product6 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="product-container">
      <h1 className="product-title">
        Pressdata<span class="trademark">®</span> Medical Gas Alarm + Analyser
      </h1>
      <img
        src="./img/products/PressData ON-L.png"
        alt="Pressdata Medical Gas Alarm + Analyser Image - 1"
        className="product-image1"
      />
      {/* <img
        src='./img/products/PressData ON-500x500.png'
        alt="Pressdata Medical Gas Alarm + Analyser Image - 2"
        className="product-image2"
      /> */}
      <h3 className="product-sub-title">
        An IoT based Tablet Operated Wireless Surgeon’s OT Control Panel for
        Monitoring + Control + Data storage + Data Analysis + Report Generation
      </h3>

      <div className="product-description-container">
        <p
          className="product-description"
          style={{ fontWeight: "bold", color: "black" }}
        >
          Application:
        </p>
        <ul className="product-description">
          <li>- Medical Gas Pipeline Alarm System</li>
          <li>- Hospital Gas Generator / Supply end Pressure Monitoring</li>
          <li>- Working on Mains Supply: 230V, 50 Hz</li>
          <li>- Each Floor for Gas leakage / choke up monitoring</li>
          <li>- Critical places – OT, ICU, Ward, etc…</li>
        </ul>
      </div>

      <p
        className="product-description"
        style={{ fontWeight: "bold", color: "black" }}
      >
        Pressure Channels:
      </p>
      <ul className="product-description">
        <li>- 5 Positive pressure channels – Oxygen, Air, N2O, CO2</li>
        <li>- 1 Negative pressure channel – Vacuum</li>
      </ul>

      <p
        className="product-description"
        style={{ fontWeight: "bold", color: "black" }}
      >
        Placement:
      </p>
      <ul className="product-description">
        <li>- Can be used as Wall mount unit or Table-top unit</li>
      </ul>

      <a
        href="./catalog/PressData Medical Gas Pressure Alarm + Analyzer (1).pdf"
        download
        className="product-button"
      >
        Download Catalog
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=wave.aim.pressdata"
        target="_blank"
        rel="noopener noreferrer"
        className="product-button"
      >
        Get the App on Google Play
      </a>
      <div className="section-container">
        <h2>Product Features</h2>
        <ul className="product-description">
          <li>Compact, Light Weight, Sleek unit</li>
          <li>Wall mountable as well as Table-top unit</li>
          <li>Standard input gas connections</li>
          <li>
            Built-in Rechargeable battery, charger and switch-over circuit
          </li>
          <li>Big Touch screen color LCD display control panel</li>
          <li>All six pressures’ Real-time continuous display</li>
          <li>
            All six pressures’ user adjustable High & Low Alarm setting – Audio
            and Video alarms
          </li>
          <li>
            Real Date-Time display with Room Temperature and Humidity
            measurement
          </li>
          <li>
            Wi-Fi Enabled Control panel for Mobile connectivity for wireless
            monitoring
          </li>
        </ul>
      </div>

      <div className="section-container">
        <h2>Specifications</h2>
        <ul className="product-description">
          <li>
            Five Positive Pressure channels: 0-100 PSI with 1% Accuracy, 1 PSI
            Resolution
          </li>
          <li>
            Vacuum channel: 0-500 mmHg with 1% accuracy, 1 mmHg resolution
          </li>
          <li>Room Temperature: 0-50°C with 1°C resolution</li>
          <li>Room Humidity: 0-100% with 1% resolution</li>
          <li>Battery backup time after full charge: 4-6 hours</li>
          <li>
            Wide Input voltage range: 90V to 260V to cover mains voltage
            fluctuations
          </li>
        </ul>
      </div>

      <p className="training-info">
        Training will be provided at Vadodara office for your installation
        engineers. Installation manual with easy-to-understand procedures will
        be provided. For any customization/additional
        feature/change/modification - wherever possible, mutual decision will be
        taken.
      </p>

      <div style={{
    backgroundColor: '#f9f9f9', // Light background
    padding: '20px',            // Spacing around content
    borderRadius: '8px',        // Rounded corners
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
    textAlign: 'center'         // Centered text
}}>
  <h2 style={{
      fontSize: '24px',         // Larger font size for title
      color: '#333',            // Dark color for contrast
      marginBottom: '10px'      // Spacing below the title
  }}>
    Need more details?
  </h2>
  <Link to="/contact" style={{
      display: 'inline-block',   // Inline block for padding
      padding: '10px 20px',      // Padding around the link
      backgroundColor: '#007bff', // Bootstrap primary color
      color: 'white',             // White text
      textDecoration: 'none',     // No underline
      borderRadius: '5px',       // Rounded corners for the button
      transition: 'background-color 0.3s', // Smooth transition for hover
  }} onMouseEnter={e => e.target.style.backgroundColor = '#0056b3'} // Darker shade on hover
     onMouseLeave={e => e.target.style.backgroundColor = '#007bff'} // Reset to original color
  >
    Contact Us
  </Link>
</div>

      <div id="footer">
        <div className="footer-line"></div>
        <div className="container text-center">
          <h4 style={{ color: "black", fontWeight: "bold" }}>
            <p>&copy; 2024 Wave Visions. All Rights Reserved.</p>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Product6;
