import React, { useState, useEffect } from "react";
import "./Testimonials.css";

export const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const testimonials = [
    {
      img: "/img/testimonials/bhailal_amin_general_hospital_logo.jfif",
      name: "Bhailal Amin General Hospital - MULTISPECIALITY",
    },
    {
      img: "/img/testimonials/MAVJAT HOSPITAL - MULTISPECIALITY.jpg",
      name: "MAVJAT HOSPITAL - MULTISPECIALITY",
    },
    {
      img: "/img/testimonials/DHIRAJ HOSPITAL - MULTISPECIALITY.jfif",
      name: "DHIRAJ HOSPITAL - MULTISPECIALITY",
    },
    {
      img: "/img/testimonials/NARHARI GENERAL HOSPITAL - MULTISPECIALITY.png",
      name: "NARHARI GENERAL HOSPITAL - MULTISPECIALITY",
    },
    {
      img: "/img/testimonials/hospitls.jpg",
      name: "YASHODHARA HOSPITAL - MULTISPECIALITY",
    },
    {
      img: "/img/testimonials/cloudnine-hospital-horamavu-bengaluru-logo.png",
      name: "CLOUD NINE HOSPITAL - MULTISPECIALITY",
    },
    {
      img: "/img/testimonials/SATYAM HOSPITAL - MULTISPECIALITY.jfif",
      name: "SATYAM HOSPITAL - MULTISPECIALITY",
    },
    {
      img: "/img/testimonials/hospitls.jpg",
      name: "SHREEJI HOSPITAL - MULTISPECIALITY",
    },
    {
      img: "/img/testimonials/YASH ORTHOPEDIC HOSPITAL.jpg",
      name: "YASH ORTHOPEDIC HOSPITAL",
    },
    {
      img: "/img/testimonials/ASHUTOSH ORTHOPEDIC HOSPITAL.jpg",
      name: "ASHUTOSH ORTHOPEDIC HOSPITAL",
    },
    {
      img: "/img/testimonials/VIROC ORTHOPEDIC HOSPITAL.png",
      name: "VIROC ORTHOPEDIC HOSPITAL",
    },
    {
      img: "/img/testimonials/hospitls.jpg",
      name: "SHREE SIDHIVINAYAK HEART HOSPITAL",
    },
    {
      img: "/img/testimonials/RHYTHM HEART INSUTITUTE.png",
      name: "RHYTHM HEART INSUTITUTE",
    },
    {
      img: "/img/testimonials/hospitls.jpg",
      name: "SHRUSHTRIL HOSPITAL - NEUROSURGERY",
    },
    {
      img: "/img/testimonials/VINS HOSPITAL - NEUROSURGERY.png",
      name: "VINS HOSPITAL - NEUROSURGERY",
    },
    {
      img: "/img/testimonials/cloudnine-hospital-horamavu-bengaluru-logo.png",
      name: "CLOUD NINE HOSPITAL - MULTISPECIALITY",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % Math.ceil(testimonials.length / 4));
    }, 5000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  const handlePrev = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + Math.ceil(testimonials.length / 4)) % Math.ceil(testimonials.length / 4));
  };

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % Math.ceil(testimonials.length / 4));
  };

  const getVisibleTestimonials = () => {
    const startIndex = currentSlide * 4;
    return testimonials.slice(startIndex, startIndex + 4);
  };

  return (
    <div id="testimonials" className="testimonials-section">
      <div className="container">
      <div className="text-center">
  <h2 className="section-title">Our Clients</h2>
</div>


        <div className="testimonial-slider">
          <div className="testimonial-list">
            {getVisibleTestimonials().map((testimonial, index) => (
              <TestimonialItem key={`${testimonial.name}-${index}`} testimonial={testimonial} />
            ))}
          </div>

          <button className="slider-control prev" onClick={handlePrev} aria-label="Previous Slide">
            &#9664;
          </button>
          <button className="slider-control next" onClick={handleNext} aria-label="Next Slide">
            &#9654;
          </button>
        </div>
      </div>
    </div>
  );
};

const TestimonialItem = ({ testimonial }) => (
  <div className="testimonial-item">
    <img src={testimonial.img} alt={testimonial.name} className="testimonial-logo" />
    <h4 className="testimonial-name">{testimonial.name}</h4>
  </div>
);
