import React from "react";
import { useNavigate } from "react-router-dom";

export const Services = () => {
  const navigate = useNavigate();

  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '4px solid #ddd',
    borderRadius: '15px',
    padding: '20px',
    marginBottom: '20px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    height: '450px',
    transition: 'transform 0.3s',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  };

  const imageStyle = {
    width: '100%',
    height: '290px',
    objectFit: 'contain',
    marginBottom: '0px',
  };

  const firstImageStyle = {
    width: '110%',
    height: '500px',
    objectFit: 'contain',
    marginBottom: '5px',
  };

  const buttonStyle = {
    padding: '12px 25px',
    backgroundColor: '#48618c',
    border: 'none',
    borderRadius: '5px',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    textDecoration: 'none',
    display: 'inline-block',
    transition: 'background-color 0.3s, transform 0.3s',
  };

  const buttonHoverStyle = {
    backgroundColor: '#0e1578',
  };

  // Map product IDs to the descriptive product routes
  const handleButtonClick = (productRoute) => {
    navigate(productRoute);
  };

  const products = [
    {
      id: 1,
      image: "/img/products/InOT image2.png",
      title: "InOT® - Intelligent Surgeon's OT Control Panel",
      route: "/InOT_Surgeons'_Control_Panel", // New route for this product
    },
    {
      id: 2,
      image: "/img/products/Hexa_Wave__LED_Operation_Theatre_Light-removebg-preview.png",
      title: "HexaWave® LED Operation Theatre Light",
      route: "/HexaWave®_LED_Operation_Theatre_Light", // New route for this product
    },
    {
      id: 3,
      image: "/img/gallary/Spotwave-2-m-removebg-preview.png",
      title: "SpotWave® LED Exam Lights For Minor OT",
      route: "/SpotWave®_LED_Exam_Light", // New route for this product
    },
    {
      id: 4,
      image: "/img/products/HexaWave_Mobile_UVC_Room_Steriliser1_Photo-removebg-preview.png",
      title: "HexaWave® - UV Mobile Sterilizer For OT and ICU",
      route: "HexaWave®_UV_Mobile_Sterilizer_for_OT_&_ICU", // New route for this product
    },
    {
      id: 5,
      image: "/img/gallary/OxyData-removebg-preview.png",
      title: "Medical Oxygen Analyzer OxyData",
      link: 'https://www.oxydata.in/', // External link for this product
    },
    {
      id: 6,
      image: "/img/products/PressData ON-L.png",
      title: "Pressdata® Medical Gas Alarm + Analyser",
      route: "/Pressdata®_Medical_Gas_Alarm_+_Analyser", // New route for this product
    },
  ];

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title" style={{ color: "black" }}>
          <h2 style={{ color: "black" }}>Our Products</h2>
        </div>
        <div className="row">
          {products.map((product, index) => (
            <div key={product.id} className="col-md-4">
              <div
                style={cardStyle}
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                <img
                  src={product.image}
                  alt={product.title}
                  style={product.id === 1 ? firstImageStyle : imageStyle}
                />
                <h3 style={{ color: "black", textAlign: 'center' }}>{product.title}</h3>
                <button
                  style={buttonStyle}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor)}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)}
                  onClick={() => product.link ? window.open(product.link, '_blank', 'noopener,noreferrer') : handleButtonClick(product.route)}
                >
                  More Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
