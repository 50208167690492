import React, { useState, useEffect } from 'react';
import './Slider.css';
import { Link } from 'react-router-dom';

const Slider = () => {
  const images = [
    { src: './img/products/InOT image.png', url: "/InOT_Surgeons'_Control_Panel" },
    { src: './img/products/HW+_Single_Dome_with_Yoke-removebg-preview 1.png', url: "/HexaWave®_LED_Operation_Theatre_Light" },
    { src: './img/products/HexaWave_Mobile_UVC_Room_Steriliser1_Photo-removebg-preview.png', url: "HexaWave®_UV_Mobile_Sterilizer_for_OT_&_ICU" },
    { src: './img/products/OxyData-C-500x500-C.png', url: "https://www.oxydata.in/", target: "_blank", rel: "noopener noreferrer", className: "page-scroll" },
    { src: './img/products/HW+ Dual Dome-41.png', url: "/HexaWave®_LED_Operation_Theatre_Light" },
    { src: "./img/products/PressData ON-L.png", url: "/Pressdata®_Medical_Gas_Alarm_+_Analyser" },
    { src: './img/products/Flowdata-removebg-preview.png', url: "/Flowdata_Medical_Gas_Flow_Analyser" },
    { src: './img/products/Boyles_Data-removebg-preview.png', url: "/Boylesdata_Digital_Monitor_For_Anaesthesia" }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 7000);
    return () => clearInterval(interval);
  }, [images.length]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const { src, url, target, rel, className } = images[currentIndex];

  return (
    <>
      <div className="slider-wrapper">
        <div className="feature-box">
          <p className="slider-text">
            Wave Visions, winner of Best Innovation Award from Government of India, is an ISO 13485 certified Pioneer Designer & Manufacturer of Medical / Surgical IoT Devices for Operation Theatre, ICU and Medical Gas Pipeline.
            Wave Visions is proud to introduce LED based OT Lights for the first time in India in 2009 and also the first in India to introduce IoT Medical Devices for OT, ICU and MGPS in 2022.
          </p>
        </div>

        <div className="slider-section">
          <button className="slider-arrow left" onClick={goToPrevious}>
            &lt;
          </button>

          <div className="slider-container">
            {url.startsWith('http') ? (
              <a href={url} target={target} rel={rel} className={className}>
                <img src={src} alt={`Slider Image`} className="slider-image" />
                <p className="slider-title">{getTitle(currentIndex)}</p>
              </a>
            ) : (
              <Link to={url}>
                <img src={src} alt={`Slider Image`} className="slider-image" />
                <p className="slider-title">{getTitle(currentIndex)}</p>
              </Link>
            )}
          </div>

          <button className="slider-arrow right" onClick={goToNext}>
            &gt;
          </button>
        </div>
      </div>
    </>
  );
};

const getTitle = (index) => {
  const titles = [
    "InOT® - Intelligent Surgeon's OT Control Panel",
    "HexaWave® - LED Operation Theatre Light Single DOME",
    "HexaWave® - UV Mobile Sterilizer For OT and ICU",
    "OxyData - C Medical Oxygen Analyser",
    "HexaWave® - Light For Major OT",
    "Pressdata® - Medical Gas Alarm + Analyser",
    "Flowdata - Medical Gas Flow Analyser",
    "Boylesdata - Digital Monitor For Anaesthesia"
  ];
  return titles[index];
};

export default Slider;
