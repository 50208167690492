import { useState , useEffect} from "react";
import emailjs from "emailjs-com";
import React from "react";
import data from "../data/data.json";
import "./ContactPage.css";

const initialState = {
  name: "",
  email: "",
  message: "",
  phone: "",
  city: "",
  products: [], // Selected products
};

const contactData = {
  address:
    "Wave Visions, A-12, Durganagar, Bh Tube Company, Old Padra Rd, Vadodara, Gujarat 390020, India",
  phone: "+916352833175",
  landline: "+91-2652324681",
  email: "info@wavevisions.in, wavevisions@gmail.com",
  facebook: "https://www.facebook.com/people/Wavevisions/100068836217168/",
  twitter: "https://twitter.com/oxydata_in",
  youtube: "https://www.youtube.com/user/ashishbhav1",
  instagram: "https://www.instagram.com/oxydata.in/",
  whatsapp: "https://wa.me/916352833175",
};

export const Contact = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [{ name, email, message, phone, city, products }, setState] =
    useState(initialState);
  const [successMessage, setSuccessMessage] = useState(""); // New state for success message

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle dropdown selection
  const handleDropdownChange = (e) => {
    const { value } = e.target;
    if (!products.includes(value) && value) {
      setState((prevState) => ({
        ...prevState,
        products: [...prevState.products, value],
      }));
    }
  };

  // Remove product
  const removeProduct = (productToRemove) => {
    setState((prevState) => ({
      ...prevState,
      products: prevState.products.filter(
        (product) => product !== productToRemove
      ),
    }));
  };

  // Reset form
  const clearState = () => setState({ ...initialState });

  // Form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Join the selected products into a string
    const productsString = products.join(", ");

    // Add the selected products to a hidden input field
    const hiddenProductsInput = document.createElement("input");
    hiddenProductsInput.type = "hidden";
    hiddenProductsInput.name = "products"; // This name should match the template on EmailJS
    hiddenProductsInput.value = productsString;

    // Add the hidden input field to the form
    e.target.appendChild(hiddenProductsInput);

    // Debugging: Log the products before sending
    console.log("Selected Products:", productsString);

    // Send email via EmailJS
    emailjs
      .sendForm(
        "service_ach4b4i", // Replace with your actual EmailJS service ID
        "template_tqxqcwm", // Replace with your actual EmailJS template ID
        e.target,
        "G196X1By1iNJhucNu" // Replace with your actual EmailJS public key
      )
      .then(
        (result) => {
          console.log(result.text);
          setSuccessMessage("Submitted successfully!"); // Set success message
          clearState(); // Clear state
          // setTimeout(() => window.location.reload(), 1000); // Refresh the page after 2 seconds
        },
        (error) => {
          console.log(error.text);
          setSuccessMessage("An error occurred. Please try again."); // Set error message if needed
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p style={{ color: "black", fontSize: "20px" }}>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" onSubmit={handleSubmit}>
                <div className="row">
                  {/* Name and Email */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name*"
                        value={name}
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email*"
                        value={email}
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Phone and City */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone*"
                        value={phone}
                        required
                        onChange={(e) =>
                          handleChange({
                            target: {
                              name: e.target.name,
                              value: e.target.value.replace(/[^0-9]/g, ""),
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        className="form-control"
                        placeholder="City*"
                        value={city}
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Message */}
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="7"
                      placeholder="Message"
                      value={message}
                      required
                      onChange={handleChange}
                      style={{
                        width: "96%",
                        height: "150px",
                        marginLeft: "15px",
                      }}
                    ></textarea>
                  </div>
                </div>

                {/* Product Dropdown */}
                <div className="form-group">
                  <label style={{ color: "black", fontSize: "20px" }}>
                    Select Product Below List:
                  </label>
                  <select
                    className="form-control"
                    onChange={handleDropdownChange}
                    value="" // Reset after selection
                    style={{ width: "96%", marginLeft: "15px" }}
                  >
                    <option value="" disabled>
                      Select a product{" "}
                    </option>
                    {[
                      "InOT® Intelligent Surgeons' Control Panel",
                      "Hexa Wave® LED Operation Theatre Light",
                      "Spot Wave® LED Exam Light",
                      "Hexawave® UV Mobile Sterilizer",
                      "Medical Oxygen Analyzer OxyData",
                      "Pressdata® Medical Gas Alarm + Analyser",
                      "Flowdata Medical Gas Flow Analyser",
                      "Boylesdata Digital Monitor For Anaesthesia",
                    ].map((product, index) => (
                      <option key={index} value={product}>
                        {product}
                      </option>
                    ))}
                  </select>
                  <div
                    style={{
                      margin: "10px",
                      fontSize: "20px",
                      marginLeft: "15px",
                    }}
                  >
                    <strong>Selected Products:</strong>
                  </div>
                </div>

                {/* Display selected products with ❌ to remove */}
                <div className="form-group">
                  <label style={{ color: "black", fontSize: "20px" }}> </label>
                  <div style={{ marginLeft: "15px" }}>
                    {products.map((product, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: "10px",
                          fontSize: "18px",
                          color: "black",
                        }}
                      >
                        {product}{" "}
                        <span
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            color: "red",
                            fontSize: "22px",
                          }}
                          onClick={() => removeProduct(product)}
                        >
                          ❌
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Success Message */}
                {successMessage && (
                  <div
                    style={{
                      margin: "15px 0",
                      color: "green",
                      textAlign: "center",
                    }}
                  >
                    {successMessage}
                  </div>
                )}

                {/* Submit button */}
                <div
                  id="success"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    type="submit"
                    className="btn btn-custom btn-lg"
                    style={{
                      margin: "0px",
                      color: "white",
                      backgroundColor: "orange",
                    }}
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* Contact Info */}
          {/* Contact Info */}
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>
                <strong>Contact Info</strong>
              </h3>
              <p style={{ color: "black" }}>
                <span>
                  <i className="fa fa-map-marker"></i>
                  <strong> Address</strong>
                </span>
                {contactData.address}
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14245.029858830916!2d73.1624479!3d22.2931231!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc61ddf939203%3A0x175bf9fd41712d12!2sWave%20Visions!5e0!3m2!1sen!2sin!4v1695217114278!5m2!1sen!2sin"
                width="100%"
                height="320"
                style={{ border: 2 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </div>
            <div className="contact-item">
              <p style={{ color: "black" }}>
                <span>
                  <i className="fa fa-phone"></i>
                  <strong> Phone</strong>
                </span>{" "}
                <a href={`tel:${contactData.phone}`} style={{ color: "black" }}>
                  {contactData.phone}
                </a>
                {contactData.landline && (
                  <>
                    <br />
                    <a
                      href={`tel:${contactData.landline}`}
                      style={{ color: "black" }}
                    >
                      {contactData.landline}
                    </a>
                  </>
                )}
              </p>
            </div>
            <div className="contact-item">
              <p style={{ color: "black" }}>
                <span>
                  <i className="fa fa-envelope"></i>
                  <strong> Email</strong>
                </span>{" "}
                {contactData.email ? (
                  <a
                    href={`https://mail.google.com/mail/?view=cm&fs=1&to=${
                      contactData.email.split(",")[0]
                    }`} // Open Gmail compose with the first email
                    target="_blank" // Open in a new tab
                    rel="noopener noreferrer" // Security measure
                    style={{ color: "darkblue", textDecoration: "none" }}
                  >
                    {contactData.email.split(",")[0]}{" "}
                    {/* Show only the first email */}
                  </a>
                ) : (
                  "loading"
                )}
              </p>
            </div>
          </div>
          {/* // Social Media Section */}
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a
                      href={contactData.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={contactData.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={contactData.youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={contactData.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={contactData.whatsapp}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer">
        <div className="container text-center">
          <h4 style={{ color: "black" }}>
            <p>&copy; 2024 Wave Visions. All Rights Reserved.</p>
          </h4>
        </div>
      </div>
      
    </div>
  );
};
