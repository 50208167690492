import React from "react";
import { useParams } from "react-router-dom";

const Policy = () => {
  // Get the product name from the URL
  const { productName } = useParams();

  const styles = {
    container: {
      maxWidth: "1100px",
      margin: "50px auto", // Center horizontally
      padding: "20px",
      fontFamily: "'Arial', sans-serif",
      fontSize: "1.2rem", // Increase the base font size
      backgroundColor: "#f9f9f9",
      borderRadius: "10px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh", // Takes full viewport height
    },
    innerContainer: {
      width: "100%",
    },
    header: {
      margin: "20px",
      fontSize: "2.0rem", // Increased header font size
      marginBottom: "20px",
      textAlign: "center",
    },
    paragraph: {
      fontSize: "1.0rem", // Increase paragraph font size
      color: "#333",
      marginBottom: "15px",
    },
    list: {
      listStyleType: "disc",
      paddingLeft: "20px",
      marginBottom: "20px",
      fontSize: "1.0rem", // Increase list item font size
    },
    link: {
      color: "#007BFF", // Blue color
      textDecoration: "none",
      fontWeight: "bold", // Make link bold
    },
    linkHover: {
      color: "#0056b3",
    },
    footer: {
      marginTop: "40px",
      textAlign: "center",
      fontSize: "1.0rem", // Increase footer font size
      color: "#777",
    },
    h2: {
      fontSize: "1.0rem", // Increase sub-header font size
      marginBottom: "10px",
      marginTop: "30px",
      color: "#444",
    },
    responsiveImage: {
      width: "100%",
      height: "auto",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <a href="https://wavevisions.in/">
          <img
            src="/img/logos/logo_wave-removebg-preview.png"
            alt="Logo"
            style={{
              display: "flex", // Ensures the flexbox layout
              justifyContent: "center", // Centers horizontally
              alignItems: "center", // Centers vertically
              height: "50px", // Default for desktop
              marginRight: "10px",
              "@media (max-width: 768px)": {
                // For mobile
                height: "20px", // Smaller logo size for mobile
                padding: "4px", // Adjust padding for mobile view
              },
              "@media (min-width: 769px)": {
                // For laptop and larger screens
                height: "30px", // Default logo size for larger screens
                padding: "8px",
              },
            }}
          />
        </a>
        <div>
          <h1 style={styles.header}>
            HexaWave<span class="trademark">®</span> LED OT Light Privacy Policy
          </h1>
        </div>
        <p style={styles.paragraph}>
          Wave Visions built the HexaWave<span class="trademark">®</span> LED OT
          Light app as a Free app. This SERVICE is provided by Wave Visions at
          no cost and is intended for use as is.
        </p>

        <p style={styles.paragraph}>
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.
        </p>

        <p style={styles.paragraph}>
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
        </p>

        <h2 style={styles.h2}>Information Collection and Use</h2>
        <p style={styles.paragraph}>
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information. The
          information that we request will be retained by us and used as
          described in this privacy policy.
          <div>
            <p style={{ paddingBlockStart: "10px", marginBottom: "5px" }}>
              Link to the privacy policy of third-party service providers used
              by the app
            </p>
            <ul style={{ margin: "7px", paddingLeft: "20px" }}>
              <li style={{ marginBottom: "5px" }}>
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "none" }}
                >
                  Google Play Services
                </a>
              </li>
              <li style={{ marginBottom: "5px" }}>
                <a
                  href="https://firebase.google.com/support/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "none" }}
                >
                  Google Analytics for Firebase
                </a>
              </li>
              <li style={{ marginBottom: "5px" }}>
                <a
                  href="https://firebase.google.com/support/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "none" }}
                >
                  Firebase Crashlytics
                </a>
              </li>
            </ul>
          </div>
        </p>

        <h2 style={styles.h2}>Log Data</h2>
        <p style={styles.paragraph}>
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through
          third-party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of
          the Service, and other statistics.
        </p>

        <h2 style={styles.h2}>Cookies</h2>
        <p style={styles.paragraph}>
          This Service does not use these “cookies” explicitly. However, the app
          may use third-party code and libraries that use “cookies” to collect
          information and improve their services. You have the option to either
          accept or refuse these cookies and know when a cookie is being sent to
          your device. If you choose to refuse our cookies, you may not be able
          to use some portions of this Service.
        </p>

        <h2 style={styles.h2}>Service Providers</h2>
        <p style={styles.paragraph}>
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <ul style={styles.list}>
          <li>To facilitate our Service;</li>
          <li>To provide the Service on our behalf;</li>
          <li>To perform Service-related services; or</li>
          <li>To assist us in analyzing how our Service is used.</li>
        </ul>

        <p style={styles.paragraph}>
          We want to inform users of this Service that these third parties have
          access to their Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </p>

        <h2 style={styles.h2}>Security</h2>
        <p style={styles.paragraph}>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </p>

        <h2 style={styles.h2}>Links to Other Sites</h2>
        <p style={styles.paragraph}>
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>

        <h2 style={styles.h2}>Children’s Privacy</h2>
        <p style={styles.paragraph}>
          These Services do not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13 years of age. In the case we discover that a child under 13
          has provided us with personal information, we immediately delete this
          from our servers. If you are a parent or guardian and you are aware
          that your child has provided us with personal information, please
          contact us so that we will be able to do the necessary actions.
        </p>

        <h2 style={styles.h2}>Changes to This Privacy Policy</h2>
        <p style={styles.paragraph}>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page.
        </p>

        <p style={styles.paragraph}>
          This policy is effective as of 2023-10-17
        </p>

        <h2 style={styles.h2}>Contact Us</h2>
        <p style={styles.paragraph}>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at :
          <a
            href="mailto:wavevisions@gmail.com"
            style={{ ...styles.link, fontWeight: "bold" }}
          >
            wavevisions@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Policy;
