import React, { useState } from 'react';
import './Gallery.css'; // Import the CSS for styling

const galleryData = [
  {
    "title": <strong>InOT<span className="trademark">®</span> - INTELLIGENT SURGEON'S OT CONTROL PANEL</strong>,
    "largeImage": "img/gallary/InOT image.png",
    "smallImage": "img/gallary/InOT Small.jpg"
  },

  {
    "title": <strong> OXYDATA -C MEDICAL OXYGEN ANALYSER</strong>,
    "largeImage": "img/gallary/OxyData-C-500x500-C.png",
    "smallImage": "img/gallary/PressData-Medical Small.png"
  },
  {
    "title": <strong>HEXAWAVE<span className="trademark">®</span> UV MOBILE STERILIZER</strong>,
    "largeImage": "img/gallary/HexaWave_Mobile_UVC_Room_Steriliser1_Photo-removebg-preview Large.png",
    "smallImage": "img/gallary/UV Mobile Sterilizer Small.jpg"
  },
  {
    "title": <strong>HEXAWAVE<span className="trademark">®</span> SINGLE DOME</strong>,
    "largeImage": "img/gallary/HW+_Single_Dome.png",
    "smallImage": "img/gallary/HW+_Single_Dome Small.png"
  },
  {
    "title": <strong>HEXAWAVE<span className="trademark">®</span> + SINGLE DOME</strong>,
    "largeImage": "img/gallary/HW+ Single Dome with Yoke Large.jpg",
    "smallImage": "img/products/HW+_Single_Dome_with_Yoke-removebg-preview Small.png"
  },
  {
    "title": <strong>SPOTWAVE<span className="trademark">®</span> + LED EXAM LIGHT</strong>,
    "largeImage": "img/gallary/Spotwave-1-Copy-300x225.png",
    "smallImage": "img/gallary/Spotwave-1-Copy-300x225.png"
  },
  {
    "title": <strong>HEXAWAVE<span className="trademark">®</span> DUAL DOME</strong>,
    "largeImage": "img/gallary/HW+ Dual Dome-41 Large.png",
    "smallImage": "img/gallary/HW+ Dual Dome-41 Small.png"
  },
  {
    "title": <strong>PRESSDATA<span className="trademark">®</span> MEDICAL GAS ALARM + ANALYZER</strong>,
    "largeImage": "img/products/Pressdata large.png",
    "smallImage":  "img/products/Pressdata small.png",
  },
  {
    "title": <strong>FLOWDATA - MEDICAL GAS FLOW ANALYSER</strong>,
    "largeImage": "img/products/Flowdata.png",
    "smallImage":  "img/products/Flowdata.png",
  },
  {
    "title": <strong>HEXAWAVE  + LED OT LIGHTS FOR MAJOR OT</strong>,
    "largeImage": "img/products/Hexa Wave® LED Operation Theatre Light.png",
    "smallImage": "img/products/Hexa_Wave__LED_Operation_Theatre_Light-removebg-preview.png",
  },
  {
    "title": <strong>BOYLESDATA - DIGITAL MONITOR FOR ANESTHESIA MACHINE</strong>,
    "largeImage": "img/products/Boyles Data.png",
    "smallImage":  "img/products/Boyles Data.png",
  },

];

// The Image component handles the full-screen view
const Image = ({ title, smallImage, largeImage, alt }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <>
      <div className="portfolio-item" onClick={handleClick}>
        <img src={smallImage} alt={alt} className="small-image" />
        <div className="small-image-title" style={{ fontWeight: "bold" }}>{title}</div>
      </div>
      
      {isFullScreen && (
        <div className="full-screen-overlay" onClick={handleClick}>
          <div className="full-screen-title">{title}</div>
          <img src={largeImage} alt={alt} className="large-image" />
        </div>
      )}
    </>
  );
};

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title" style={{ margin: "5px" }}>
          <h2>Gallery of Product Images</h2>
          <p style={{ color: "black", fontWeight: "bold" }}>
            A gallery showcasing our products, including LED OT lights, intelligent control panels, and medical gas analyzers, displayed for a streamlined view.
          </p>
          <p style={{ fontWeight: "bold" }}>Click the Image to View Full Screen</p>
        </div>
        <div className="portfolio-items">
          {galleryData.map((d, i) => (
            <div key={`${d.title}-${i}`} className="portfolio-item-wrapper">
              <Image
                title={d.title}
                smallImage={d.smallImage}
                largeImage={d.largeImage}
                alt={d.title}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
