import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import './Navigation.css';

export const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  

  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown visibility

  const handleNavigation = (path, hash) => {
    setIsMenuOpen(false); // Close menu
    navigate(path);
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  };

  const handleLogoClick = () => {
    setIsMenuOpen(false); // Close menu
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate("/");
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const menu = document.getElementById("bs-example-navbar-collapse-1");
      const toggleButton = document.querySelector(".navbar-toggle");
      if (menu.classList.contains("in") && !menu.contains(event.target) && !toggleButton.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

// Check if the current pathname is any of the policy pages
const isPolicyPage = 
  location.pathname === "/HexaWave/Privacy-Policy" ||
  location.pathname === "/HexaWaveUv/Privacy-Policy" ||
  location.pathname === "/PressData/Privacy-Policy" ||
  location.pathname === "/BoylesData/Privacy-Policy" ||
  location.pathname === "/SpotWave/Privacy-Policy" ||
  location.pathname === "/Inot/Privacy-Policy" ||
  location.pathname === "/FlowData/Privacy-Policy"||
  location.pathname === "/OxyData/Privacy-Policy" ||
  location.pathname === "/ProctoCam/Privacy-Policy" ||
  location.pathname === "/SphinctoPress/Privacy-Policy";


// If on any policy page, don't render the navigation
if (isPolicyPage) {
  return null; // or return a different element if needed
}



  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
      <div className="navbar-header">
      <button
        type="button"
        className="navbar-toggle collapsed"
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
      >
        <span className="sr-only">Toggle navigation</span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>

      {/* Use handleLogoClick function for the logo */}
      <a
        className="navbar-brand page-scroll"
        onClick={handleLogoClick}
      >
        <img
    src="/img/logos/logo_wave-removebg-preview.png"
    alt="Logo"
    className="logo-img"
  />
      </a>
    </div>
    <div className="navbar-right">
  <p>
    For Help Call:{" "}
    <a href="tel:+91265232481">
      +91265232481
    </a>{" "}
    or Email:{" "}
    <a
      href="https://mail.google.com/mail/?view=cm&fs=1&to=info@wavevisions.in"
      target="_blank"
      rel="noopener noreferrer"
    >
      info@wavevisions.in
    </a>
  </p>
</div>


        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
          <li>
              <a href="#" onClick={() => handleNavigation("/", "#page-top")} className="page-scroll" style={{ fontWeight: "bold", color: "black" }}>
                Home
              </a>
              </li>
            <li className="dropdown">
              <a href="#services" className="page-scroll dropbtn" style={{ fontWeight: "bold", color: "black" }}>
                Products <i className="fa fa-caret-down"></i>
              </a>
              <ul className="dropdown-content">
                <li>
                  <Link to="/InOT_Surgeons'_Control_Panel" className="page-scroll">
                    InOT<span class="trademark">®</span> Intelligent Surgeons' Control Panel
                  </Link>
                </li>
                <li>
                  <Link to="/HexaWave®_LED_Operation_Theatre_Light" className="page-scroll">
                  HexaWave<span class="trademark">®</span> LED Operation Theatre Light
                  </Link>
                </li>
                <li>
                  <Link to="/SpotWave®_LED_Exam_Light" className="page-scroll">
                    SpotWave<span class="trademark">®</span> LED Exam Light
                  </Link>
                </li>
                <li>
                  <Link to="HexaWave®_UV_Mobile_Sterilizer_for_OT_&_ICU" className="page-scroll">
                    Hexawave<span class="trademark">®</span> UV Mobile Sterilizer
                  </Link>
                </li>
                <li>
                  <a href="https://www.oxydata.in/" target="_blank" rel="noopener noreferrer" className="page-scroll">
                     Medical Oxygen Analyzer OxyData
                  </a>
                </li>

                <li>
                  <Link to="/Pressdata®_Medical_Gas_Alarm_+_Analyser" className="page-scroll">
                    Pressdata<span class="trademark">®</span> Medical Gas Alarm + Analyser
                  </Link>
                </li>
                <li>
                  <Link to="/Flowdata_Medical_Gas_Flow_Analyser" className="page-scroll">
                    Flowdata Medical Gas Flow Analyser
                  </Link>
                </li>
                <li>
                  <Link to="/Boylesdata_Digital_Monitor_For_Anaesthesia" className="page-scroll">
                    Boylesdata Digital Monitor For Anaesthesia
                  </Link>
                </li>
                {/* Continue with other products */}
              </ul>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => handleNavigation("/", "#portfolio")}
                className="page-scroll"
                style={{ fontWeight: "bold", color: "black" }}
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => handleNavigation("/", "#testimonials")}
                className="page-scroll"
                style={{ fontWeight: "bold", color: "black" }}
              >
                Our Clients
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => handleNavigation("/", "#about")}
                className="page-scroll"
                style={{ fontWeight: "bold", color: "black" }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={() => handleNavigation("/", "#contact")}
                className="page-scroll"
                style={{ fontWeight: "bold", color: "black" }}
              >
                Contact US
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
